import Editor from "@toast-ui/editor";

function renderYoutube(wrapperId, youtubeId) {
    const el = document.querySelector(`#${wrapperId}`);

    el.innerHTML = `<iframe class="mx-auto rounded-lg shadow-lg border max-w-full" width="560" height="315" src="https://www.youtube.com/embed/${youtubeId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
}

export default function () {
    Editor.codeBlockManager.setReplacer('youtube', youtubeId => {
        try {
            let url = new URL(youtubeId);

            if (url.search) {
                youtubeId = url.searchParams.get('v');
            } else {
                youtubeId = url.pathname
            }
        } catch (e) {

        }

        // Indentify multiple code blocks
        const wrapperId = `yt${Math.random()
            .toString(36)
            .substr(2, 10)}`;

        // Avoid sanitizing iframe tag
        setTimeout(renderYoutube.bind(null, wrapperId, youtubeId), 0);

        return `<div id="${wrapperId}"></div>`;
    });
}

